export const paths = {
  dashboard: () => '/',
  auth: {
    login: (queryString?: string) =>
      '/auth/login' + (queryString ? `?${queryString}` : ''),
  },
  attributes: {
    list: () => '/attributes',
    show: (uuid = ':uuid') => `/attributes/${uuid}`,
    create: () => '/attributes/create',
    edit: (uuid = ':uuid') => `/attributes/${uuid}/edit`,
  },
  categories: {
    list: () => '/categories',
    create: () => '/categories/create',
    edit: (uuid = ':categoryUuid') => `/categories/${uuid}/edit`,
    show: (uuid = ':categoryUuid') => `/categories/${uuid}`,
  },
  users: {
    list: () => '/users',
    create: () => '/users/create',
    edit: (uuid = ':uuid') => `/users/${uuid}/edit`,
    show: (uuid = ':uuid') => `/users/${uuid}`,
  },
  products: {
    list: () => '/products',
    create: () => '/products/create',
    edit: (uuid = ':uuid') => `/products/${uuid}/edit`,
    show: (uuid = ':uuid') => `/products/${uuid}`,
  },
};
