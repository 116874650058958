import { useApolloClient, useMutation } from '@apollo/client';
import {
  ButtonGroup,
  ConfirmModal,
  IconButton,
  useNotifications,
} from '@petmate/ui';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Role, useCan } from 'src/auth/hooks/use-can';
import { GetCategoryQuery } from 'src/categories/gql/queries/get-category.query';
import { GetRootCategoriesQuery } from 'src/categories/gql/queries/get-root-categories.query';
import { paths } from 'src/shared/constants/paths';
import { DeleteCategoryMutation } from '../../gql/mutations/delete-category.mutation.gql';
import { Category } from 'src/shared/types/category.type';

export const ListCategoriesActions = ({ row }: { row: Category }) => {
  const client = useApolloClient();
  const { can } = useCan();
  const { success, error } = useNotifications();
  const [modalOpen, setModalOpen] = useState(false);
  const [mutate] = useMutation(DeleteCategoryMutation, {
    onCompleted: () => {
      client.refetchQueries({
        include: [GetRootCategoriesQuery, GetCategoryQuery],
      });
      success({ title: 'Success', message: 'Category deleted successfully!' });
    },
    onError: () => {
      error({ title: 'Error', message: 'An error occurred while deleting!' });
    },
  });

  const handleModalSubmit = () => {
    setModalOpen(false);
    mutate({ variables: { uuid: row.uuid } });
  };

  return (
    <ButtonGroup>
      <IconButton
        as={Link}
        to={paths.categories.show(row.uuid)}
        iconSize={16}
        variant="info"
        name="eye"
      />
      {can([Role.Admin, Role.Manager]) && (
        <IconButton
          as={Link}
          state={{
            redirect: window.location.pathname + window.location.search,
          }}
          to={paths.categories.edit(row.uuid)}
          iconSize={16}
          variant="warning"
          name="edit"
        />
      )}
      {can([Role.Admin]) && (
        <IconButton
          iconSize={16}
          variant="error"
          name="trash"
          onClick={() => setModalOpen(true)}
        />
      )}
      <ConfirmModal
        cancelText="Nie, zostaw"
        confirmText="Tak, usuń"
        title={`Czy na pewno chcesz usunąć kategorie: ${row.name}?`}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleModalSubmit}
        open={modalOpen}
      />
    </ButtonGroup>
  );
};
