import { Button, DataTable, Icon } from '@petmate/ui';
import { PageHeader } from '../../../shared/components/page-header';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useProtectedRoute } from 'src/auth/hooks/use-protected-route';
import { Role, useCan } from 'src/auth/hooks/use-can';
import { paths } from 'src/shared/constants/paths';
import { useGetCategoriesQuery } from 'src/categories/gql/queries/get-categories.query';
import { ListCategoriesActions } from './list-categories.actions';
import { columns } from './list-categories.columns';
import { Category } from 'src/shared/types/category.type';
import { Page } from 'src/shared/components/page';
import { useState } from 'react';

export interface ListCategoriesSort {
  column: any;
  direction: 'asc' | 'desc';
}

export const ListCategories = () => {
  useProtectedRoute([Role.Admin]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort, setSort] = useState<ListCategoriesSort>({
    column: 'order',
    direction: 'asc',
  });
  const { can } = useCan();
  const params = useParams<{ categoryUuid: string }>();
  const isSubCategory = !!params.categoryUuid;
  const { data, error } = useGetCategoriesQuery({
    fetchPolicy: 'network-only',
    variables: {
      filters: {
        parentUuid: { value: params.categoryUuid || null },
      },
      relations: ['children', 'attributes'],
      sort,
    },
  });

  const handleSortChange = (sort: ListCategoriesSort) => {
    setSort(sort);
    const params: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    setSearchParams({
      ...params,
      sort: sort.column,
      sortDirection: sort.direction,
    });
  };

  if (error) return <div>Error...</div>;

  return (
    <Page width={60}>
      <PageHeader
        title={isSubCategory ? `Category: ` : 'Categories'}
        canGoBack={isSubCategory}
        actions={[
          can([Role.Admin, Role.Manager]) ? (
            <Button
              as={Link}
              state={{
                redirect: window.location.pathname + window.location.search,
              }}
              to={paths.categories.create()}
              label="Add category"
              leftComponent={<Icon name="plus" size={20} />}
              size="medium"
            />
          ) : null,
        ]}
      />
      <DataTable<Category>
        columns={columns}
        data={data.categories}
        actions={(row) => <ListCategoriesActions row={row} />}
        keyExtractor={(row) => row.uuid}
        onSortChange={handleSortChange}
        sort={sort}
      />
    </Page>
  );
};
