import { Page } from 'src/shared/components/page';
import { PageHeader } from 'src/shared/components/page-header';
import { Button, DataTable, Icon, TextField } from '@petmate/ui';
import { Link, useSearchParams } from 'react-router-dom';
import { paths } from 'src/shared/constants/paths';
import { ListAttributesActions } from './list-attributes.actions';

import { Attribute } from 'src/shared/types/attribute.type';
import { useGetAttributesQuery } from 'src/attributes/gql/queries/get-attributes.query';
import { useState } from 'react';
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
} from 'src/shared/constants/pagination';
import { useDebounceValue } from 'src/shared/hooks/use-debounce-value.hook';
import { FilterOperator } from 'src/shared/types/filter-input.type';

export interface ListAttributesSort {
  column: any;
  direction: 'asc' | 'desc';
}

export const ListAttributes = () => {
  const [searchParams] = useSearchParams();
  const [query, setQuery] = useState<string>('');
  const debouncedQuery = useDebounceValue(query, 300);
  const page = Number(searchParams.get('page')) || DEFAULT_PAGE;
  const pageSize = Number(searchParams.get('pageSize')) || DEFAULT_PAGE_SIZE;
  return (
    <Page>
      <PageHeader
        title="Attributes"
        actions={[
          <Button
            as={Link}
            state={{
              redirect: window.location.pathname + window.location.search,
              page,
              pageSize,
            }}
            to={paths.attributes.create()}
            label="Add attribute"
            leftComponent={<Icon name="plus" size={20} />}
            size="medium"
          />,
        ]}
      />
      <TextField
        label="Search"
        onChange={(e) => setQuery(e.target.value)}
        onBlur={() => {}}
        name="search"
        value={query}
      />
      <AttributesTable query={debouncedQuery} />
    </Page>
  );
};

export const AttributesTable = ({ query }: { query: string }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [sort, setSort] = useState<ListAttributesSort>({
    column: 'createdAt',
    direction: 'desc',
  });
  const page = Number(searchParams.get('page')) || DEFAULT_PAGE;
  const pageSize = Number(searchParams.get('pageSize')) || DEFAULT_PAGE_SIZE;

  const { data } = useGetAttributesQuery({
    variables: {
      pagination: { page, pageSize },
      sort,
      filters: {
        name: { value: `%${query}%`, operator: FilterOperator.ilike },
      },
    },
    fetchPolicy: 'network-only',
  });

  const { payload, pagination } = data.attributes;

  const handleSortChange = (sort: ListAttributesSort) => {
    setSort(sort);
    const params: Record<string, string> = {};

    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    setSearchParams({
      ...params,
      sort: sort.column,
      sortDirection: sort.direction,
    });
  };

  return (
    <DataTable<Attribute>
      data={payload}
      pagination={{ ...pagination, page, pageSize }}
      onPageChange={setSearchParams}
      onPageSizeChange={setSearchParams}
      onSortChange={handleSortChange}
      sort={sort}
      columns={[
        { accessor: 'name', label: 'Name', width: '40%', sortable: true },
        {
          accessor: 'displayName',
          label: 'Display name',
          width: '30%',
          sortable: true,
        },
        { accessor: 'unit', label: 'Unit', width: '15%', sortable: true },
        {
          accessor: 'valueType',
          label: 'Type',
          width: '15%',
        },
      ]}
      actions={(row) => <ListAttributesActions row={row} />}
    />
  );
};
